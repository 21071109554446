import React from 'react';

import { ICheckBox } from '../../../../types';
import * as Styled from '../check-box-firmik/check-box-formik.styled';

export const CheckBox = ({
  isChecked,
  onClick,
  label,
  height,
  background,
  colorText,
  ...props
}: ICheckBox) => (
  <Styled.Label id='checkbox' {...props}>
    <Styled.Input type='checkbox' checked={isChecked} onChange={onClick} />
    <Styled.Span height={height} colorText={colorText} background={background} className='text'>
      <span>{label}</span>
    </Styled.Span>
  </Styled.Label>
);
