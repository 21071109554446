import { AxiosError } from 'axios';
import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { onError } from '../../module/common/services';
import { walletService } from '../../services/wallet/wallet.service';
import { IAllFilters, IAllList, IAuthError, ICurrentAddressData } from '../../types';

const all = (data: IAllFilters): UseQueryResult<IAllList> =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.ALL_LIST, data.page, data.keyword],
    async () => await walletService.all(data)
  );

const get = (address: string): UseQueryResult<ICurrentAddressData> =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.CURRENT_ADDRESS, address],
    async () => await walletService.get(address),
    {
      enabled: address.length > 34,
      retry: 0
      // refetchInterval: 1000,
      // cacheTime: 6000030000
      // onError: (err: AxiosError<IAuthError>) => {
      //   if (err.status.code === 125){
      //     setFillederror
      //   }
      //   onError(err);
      // }
    }
  );

export const useQueryWallet = {
  all,
  get
};
