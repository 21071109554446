import * as Yup from 'yup'
import {validateSchema} from '../../common/validation/validate-shema'

export const validationSchemaCreateWallet = Yup.object().shape({
  name: Yup.string().required('is required'),
  address: Yup.string().required('is required'),
  token: Yup.object()
    .shape({
      name: Yup.string().required('is required')
    })
    // .required('is required')
});


export const validationChangePassword = Yup.object().shape({
  password: validateSchema.password,
  newPassword: validateSchema.password
});
