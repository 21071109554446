import { AxiosError } from 'axios';
import { Form, Formik, getIn, useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';

import { Icons } from '../../../../assets';
import { useMutationWallet, useQueryWallet } from '../../../../hooks';
import { COLORS } from '../../../../theme';
import { IAuthError, INewWallet } from '../../../../types';
import { Button, Input, Loader } from '../../../common/component';
import { DropDownlist } from '../../../common/component/drop-down-list/drop-downlist';
import { PopupLayout } from '../../../common/component/popup-layout/popup-layout';
import { Portal } from '../../../common/component/portal';
import { TitleCommon } from '../../../common/styles';
import { validationSchemaCreateWallet } from '../../validation/shema';
import { CreateWalletWrapper } from './create-wallet.styled';

interface ICreateWallet {
  page: number;
}

interface ICreateWalletIndex {
  onClose: () => void;
  page: number;
}

const CreateWalletIndex = ({ onClose, page }: ICreateWalletIndex) => {
  const { isValid, setFieldValue, values, setFieldError,errors } = useFormikContext();
  const { data: currentAddressInfo, isFetched } = useQueryWallet.get(getIn(values, 'address'));
  const [tokens, setTokens] = useState<any[]>([]);
  
  useEffect(() => {
    if (currentAddressInfo?.currentAddressData) {
      setTokens(currentAddressInfo.currentAddressData);
    }
  }, [currentAddressInfo?.currentAddressData, isFetched]);

  const { isLoading, mutate: mutateCreateAddress } = useMutationWallet.post({
    options: {
      addOnSuccess: () => {
        onClose();
      },
      addOnError: (err: any) => {
        if (err.errorCode === 120) {
          setFieldError('name', err.message);
        }
        if ([121, 122].includes(err.errorCode)) {
          setFieldError('address', err.message);
        }
      }
    }
  });

  const onAddWallet = (data: INewWallet) => {
    mutateCreateAddress({
      name: data.name,
      address: data.address,
      token: data.token?.name,
      page
    });
  };

  return (
    <>
      <Portal>
        <PopupLayout onClose={onClose}>
          <TitleCommon>Add Wallet</TitleCommon>

          <CreateWalletWrapper>
            <Input name='name' placeholder='name' width='25rem' height='2.9375rem' label='name' />

            <Input
              name='address'
              placeholder='address'
              label='Address'
              width='25rem'
              height='2.9375rem'
              addOptionalOnChange={() => {
                setTokens([]);
              }}
            />

            {tokens && tokens.length ? (
              <DropDownlist
                list={
                  tokens.map((value) => ({
                    name: value.name
                  })) ?? []
                }
                name='token'
                title='Token'
                listStyle={{ maxWidth: '25rem', top: '3rem' }}
                isToUp={false}
                addOptionalOnChange={(_, value) => {
                  setFieldValue(
                    'balance',
                    tokens.find((v) => v.name === value.name)?.balance
                  ).then();
                }}
                wrapperStyle={{
                  position: 'relative',
                  width: '25rem',
                  border: `0.0625rem solid ${COLORS.black115}`,
                  height: '2.9375rem',
                  margin: '1.25rem 0 0.25rem 0',
                  background: 'white',
                  borderRadius: '0.25rem'
                }}
              />
            ) : null}

            <Button
              content={
                !isLoading ? (
                  'Add Wallet'
                ) : (
                  <Loader size='small' color={COLORS.green} height='auto' />
                )
              }
              width='25rem'
              height='2.9375rem'
              mt='1.125rem'
              mb='2.125rem'
              color={COLORS.white}
              type='button'
              variant='primaryGreen'
              disabled={isLoading || !isValid}
              onClick={onAddWallet.bind(this, values as INewWallet)}
            />
          </CreateWalletWrapper>
        </PopupLayout>
      </Portal>
    </>
  );
};

export const CreateWallet = ({ page }: ICreateWallet) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSetIsOpen = (flag: boolean) => {
    setIsOpen(flag);
  };

  return (
    <>
      <Button
        width='16.75rem'
        height='2.9375rem'
        content='Add Wallet'
        startIcon={Icons.plusIcon}
        onClick={onSetIsOpen.bind(this, true)}
        type='button'
        variant='primaryGreen'
        cursor='pointer'
      />

      {isOpen && (
        <Formik
          initialValues={{
            name: '',
            address: '',
            token: { name: '' },
            balance: '0'
          }}
          onSubmit={() => {}}
          validationSchema={validationSchemaCreateWallet}
        >
          {() => (
            <Form>
              <CreateWalletIndex onClose={onSetIsOpen.bind(this, false)} page={page} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

// TSTVYwFDp7SBfZk7Hrz3tucwQVASyJdwC7
