import styled, { css } from 'styled-components';

import { COLORS, FONTS, SHADOWS, SPACES, TRANSITIONS } from '../../../../theme';
import { Center, Fonts, IconCommon } from '../../styles';
import { Button } from '../buttons';

const layoutCss = css`
    display: table;
    width: 100%;
    table-layout: fixed;
`;

const tHeadRowAndTDataCommonStyle = css`
    padding: ${`${SPACES.xxxxl} ${SPACES.xxxxl}`};
`;

const cellControlledSizes = css`
    &.cell {
        width: 12.5rem;
    }
`;

// ================= table components START ====================//

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 2.5rem;
    margin-bottom: 1.5rem;
`;

export const Head = styled.thead`
    ${layoutCss};
    border: 0.0625rem solid ${COLORS.black115};
    border-radius: ${SPACES.s};

    position: sticky;
    top: 0;
    z-index: 2;

    background: ${COLORS.white};
`;

export const Body = styled.tbody`
    ${layoutCss}
`;

export const HeadRow = styled.th`
    height: 3.125rem;
    font-weight: ${FONTS.WEIGHTS.semi_bold};
    font-size: ${FONTS.SIZES.l};
    text-align: center;
    color: ${COLORS.black};
    border-right: 0.0625rem solid ${COLORS.black115};

    & > #checkbox {
        width: fit-content;
        margin: 0 auto;
        margin-left: -13px;
    }
    
    &:last-child {
        border-right: none;
    }

    &:first-child {
        width: 4.875rem;
    }

    &.link {
        width: 4.375rem;
    }

    &.button {
        width: 4.375rem;
        padding-left: 1.4375rem;
    }

    &.edit {
        width: 4.375rem;
        padding: 0;
    }

    ${cellControlledSizes}
`;

export const Row = styled.tr`
    ${layoutCss};

    & > td > strong {
        text-transform: none;
    }

    &.body {
        &:nth-child(odd) {
            background-color: ${COLORS.white50};
            border-bottom: 0.0625rem solid ${COLORS.black115};
        }

        &:nth-child(even) {
            border-bottom: 0.0625rem solid ${COLORS.black115};
        }
    }

    &:last-child {
        border-radius: 0 0 ${SPACES.s} ${SPACES.s};
    }
`;
export const NowData = styled.div`
    width: 100%;
    min-height: 12.5rem;

    ${Center};
    ${Fonts};
`;
export const Data = styled.td`
    border-right: 0.0625rem solid ${COLORS.black115};

    &:first-child {
        width: 4.875rem;
        border-left: 0.0625rem solid ${COLORS.black115};
    }

    & > #checkbox {
        width: fit-content;
        margin: 0 auto;
        margin-left: -13px;
    }

    font-weight: ${FONTS.WEIGHTS.normal};
    font-size: ${FONTS.SIZES.m};
    vertical-align: middle;
    color: ${COLORS.black};

    &.link {
        width: 4.375rem;
    }

    &.button {
        width: 4.375rem;
        padding-left: 1.4375rem;

        & > button {
            padding-top: 1rem;
        }
    }

    &.doubleButton {
        width: 8.75rem;
        padding-left: 3.5rem;
        cursor: text !important;

        & > button {
            padding-top: 1rem;
        }

        & > button > div {
            cursor: text !important;
        }
    }

    &.edit {
        width: 4.375rem;
        padding: 0;
    }

    &.confirm {
        width: 4.375rem;
        padding: 0;

        & > button > div {
            background: white;
            height: 1.625rem;
        }
    }

    &.cancel {
        width: 4.375rem;
        padding: 0;

        & > button > div {
            background: white;
            height: 1.625rem;
        }
    }

    &.title {
        position: relative;
        height: 2.5rem;
        padding: 0 ${FONTS.SIZES.xxsm};

        & > strong {
            text-align: center;
        }

        &:hover {
            cursor: pointer;
        }
    }

    ${cellControlledSizes}
`;
// ================= table components END ====================//

// ================= helper components START ====================//

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ImagePlaceholderThumb = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.5rem;
    height: 2.5rem;
    margin-left: ${({ theme }) => theme.SPACES.s};

    border-radius: 50%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.COLORS.gray200};
    cursor: pointer;
`;

// ================== icons END =================================//

// ================== item card fields START ====================//

const truncateStringStyles = css<{ linesToTruncate?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: ${({ linesToTruncate }) => linesToTruncate ?? 1};
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const labelPriceCommonStyles = css`
    ${Fonts};
`;

export const ItemImage = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    margin-left: ${SPACES.s};

    object-position: center;
    object-fit: cover;
    overflow: hidden;

    border-radius: 50%;
    cursor: pointer;
`;
// ${labelPriceCommonStyles}

export const ItemLabel = styled.strong<{
  linesToTruncate?: number;
  tooltipText: string;
  background?: string;
}>`
    display: block;
    ${truncateStringStyles}
    ${labelPriceCommonStyles}
    cursor: pointer;

    &.tooltip::after {
        position: absolute;
        bottom: 70%;
        left: 40%;
        width: max-content;
        max-width: 12rem;
        content: ${({ tooltipText }) => `'${tooltipText}'`};
        padding: ${`${SPACES.xxs} ${SPACES.xs}`};
        font-weight: ${FONTS.WEIGHTS.normal};
        font-size: ${FONTS.SIZES.s};
        border: 0.0625rem solid ${COLORS.black};
        border-radius: ${SPACES.m};
        word-break: break-all;
        background-color: ${COLORS.white};
        box-shadow: ${SHADOWS.xs};

        visibility: hidden;
        z-index: 11;

        transition: visibility ${`${TRANSITIONS.duration.fast} ${TRANSITIONS.function.linear}`};
    }

    ${({ tooltipText }) =>
            tooltipText?.length >= 17 &&
            css`
                &.tooltip:hover::after {
                    visibility: visible;
                }
            `}

    ${({ background }) =>
            background &&
            css<{ background?: string }>`
                color: ${({ background }) => background} !important;

                &::before {
                    content: '';
                    display: inline-block;

                    margin-right: 0.5rem;
                    height: 0.75rem;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    background: ${({ background }) => background ?? COLORS.black};
                }
            `}
`;

export const ItemInput = styled.input``;

export const ItemPrice = styled.strong`
    ${labelPriceCommonStyles}
`;

export const GenderAgeWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${SPACES.xxsm};
`;

export const EditButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;

    cursor: pointer;

    &:hover > .icon {
        background: ${COLORS.green};
    }
`;

export const Avatar = styled.img`
    height: 1.3rem;
    aspect-ratio: 1/1;

    cursor: pointer;
`;

export const ButtonTable = styled(Button)`
    width: 4rem;
    height: 2rem;

    font-size: ${FONTS.SIZES.s};
    line-height: ${FONTS.SIZES.xxxxl};
`;
