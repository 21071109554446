import styled, { css } from 'styled-components';

import { COLORS } from '../../theme';
import { Row } from '../common/component/table/table.styled';
import { SubTitleCommon, TitleCommon } from '../common/styles';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 2.75rem 4.0625rem 0 4.0625rem;
`;

export const HeaderTitle = styled(SubTitleCommon)`
  width: fit-content;
  display: flex;
  gap: 1.125rem;
  font-size: 0.9375rem;
  font-weight: 500;
`;
export const HeaderPageInfo = styled(TitleCommon)`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.125rem;

  & > .edit_title_page {
    display: flex;
    align-items: center;
  }

  & > .edit_title_page input {
    font-size: 2.25rem !important;
    font-weight: 500;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
`;
export const WrapperFlex = styled.div`
  width: 90%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 1rem;
  & > p {
    width: 50%;
  }
`;

export const WalletContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  //background: antiquewhite;
  align-items: center;
  padding: 3.5rem 4.375rem 0 4.375rem;

  & .search > div {
    top: 1.0625rem;
  }
`;
export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  & > div {
  }
`;
export const WrapperPagination = styled.div`
  width: 56.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const WrapperButton = styled.div`
  width: 16.875rem;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const BodyRow = styled(Row)<{ isEdit?: boolean; isBlur?: boolean }>`
  & > td.button {
    cursor: pointer;
  }

  & > td > strong.token {
    text-align: center;
    text-transform: none;
  }

  & > td > strong > div.wrapper {
    text-transform: uppercase;
  }

  & > td > strong > div > ul.list > li {
    text-transform: uppercase;
  }

  &.edit_item {
    & > .confirm {
      background: ${COLORS.greenActiveButton};
      text-align: center;
      align-content: end;
      cursor: pointer;

      &:hover {
        background: ${COLORS.green};
      }
    }
    & > .cancel {
      background: ${COLORS.pink};
      text-align: center;
      align-content: end;
      cursor: pointer;

      &:hover {
        background: ${COLORS.pinkActive};
      }
    }
    & input {
      outline: none;
      caret-color: black;
      background: none;
      border: 0.0625rem solid ${COLORS.black100};
      border-radius: 0;
      margin: 0.125rem 0;
    }
  }
  ${({ isEdit }) =>
    isEdit &&
    css`
      & > td > strong {
        font-weight: 600;
      }
    `}
  ${({ isBlur }) =>
    isBlur &&
    css`
      opacity: 0.5;
    `}
`;
export const WrapperFilterTokens = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 2rem;
  
  flex-direction: row;

  & > div.wrapper {
    border: 0.0625rem solid ${COLORS.black115};
    border-radius: 0.25rem;
    height: 2.9375rem;
    display: flex;
    align-items: center;
  }
`;
export const FilterCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2.9375rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
`;

export const NoData = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
